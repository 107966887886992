/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-29 14:54:09
 * @Module: 404
 */
 <template>
  <div class="error">
    <el-result title="404" subTitle="糟糕，页面找不到了...">
      <template slot="icon">
        <!-- <el-image :src="require('@/static/logo1.png')" style="width:200px;"></el-image> -->
      </template>
      <template slot="extra">
        <el-button type="primary" size="mini" @click="$router.go(-1)">返回</el-button>
      </template>
    </el-result>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.error {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
  // background-image: url(~@/static/image/home_bg_2.png);
  background-repeat: no-repeat;
  background-position: right bottom;
}
</style>